import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BehaviorSubject } from "rxjs";
import { history, fetchWrapper } from "../_helpers";
import http from "../http-common";
// create slice

const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });
const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;
const usersUrl = `${process.env.REACT_APP_API_URL}/users`;
// exports
const userSubject = new BehaviorSubject(null);
export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    // initialize state from local storage to enable user to stay logged in
    user: JSON.parse(localStorage.getItem("user")),
    error: null,
  };
}

function createReducers() {
  return {
    logout,
  };

  function logout(state) {
    state.user = null;

    localStorage.removeItem("user");
    localStorage.removeItem("token");
    stopRefreshTokenTimer();
    userSubject.next(null);
    history.navigate("/login");
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;

  const usersUrl = `${process.env.REACT_APP_API_URL}/users`;
  return {
    login: login(),
  };

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async ({ email, password }) =>
        await http.post("/users/login", {
          password: password,
          email: email,
        })
      //http.post("/users/login", {

      //    "password": password,
      //    "email": email
      //})
      //fetchWrapper.post(`${usersUrl}/login`, { email, password })
    );
  }
}

function refreshToken() {
  return;
  /*fetchWrapper.post(`${baseUrl}/refresh-token`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });*/
}

let refreshTokenTimeout;

function startRefreshTokenTimer() {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split(".")[1]));

  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000);
  const timeout = expires.getTime() - Date.now() - 60 * 1000;
  refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
  clearTimeout(refreshTokenTimeout);
}

function createExtraReducers() {
  return {
    ...login(),
  };

  function login() {
    var { pending, fulfilled, rejected } = extraActions.login;
    return {
      [pending]: (state) => {
        state.error = null;
      },
      [fulfilled]: (state, action) => {
        const user = action.payload;

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user.data.user));
        localStorage.setItem("token", user.data.token);
        ////console.log(user.data.user);
        ////console.log(user.data.message);
        ////console.log(user.data.token);
        ////console.log(localStorage.getItem("user"));
        state.user = user.data.user;
        userSubject.next(user.data.user);
        startRefreshTokenTimer();

        // get return url from location state or default to home page
        const { from } = history.location.state || { from: { pathname: "/" } };
        history.navigate(from);
      },
      [rejected]: (state, action) => {
        state.error = action.error;
      },
    };
  }
}
