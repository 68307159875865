import React, { useState, useEffect, useRef } from "react";
import UploadService from "../_services/FileUploadService";
import { useNavigate } from "react-router-dom";
import { CloseButton } from "../components/styles/CalendarStyles";
import Modal from "react-modal";
import Imgligth from "./Imgligth";
import { Box, Typography, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import { tokens } from "../theme";
import ImgBibl from "./ImgBibl";
import { toast } from "react-toastify";

const BiblImg = ({ defaultList, onChange }) => {
  const confirm = useConfirm();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageSelect, setImageSelect] = useState();
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  const [message, setMessage] = useState([]);
  const [imageInfos, setImageInfos] = useState([]);
  const [loading, setLoading] = useState(false);
  const progressInfosRef = useRef(null);
  const history = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const [selectedLight, setSelectedLight] = useState("");
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      background: "rgba(76, 175, 80, 0.3)",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 1000 },
  };
  const openForm = () => {
    setSelectedModal("Imgligth");
    openModal();
  };

  // Open Modal Function
  const openModal = () => {
    setModalOpen(true);
  };

  // Close Modal Function
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleEventClick = (img) => {
    if (img) {
      console.log("handleEventClick", img);
      // dispatch(search_appointment(clickInfo.event._def.publicId));
      setSelectedLight(img);
      setSelectedModal("Imgligth");

      openModal();
    }
  };

  /*const handleTrimit = (valtrimite) => {
    // const values = [...itemList];
    // values[index].value = e.target.value;
    //setSelList(valtrimite);
    onChange(valtrimite);
  };*/
  function getExtension(filename) {
    return filename.split('.').pop()
  };
  useEffect(() => {
    setLoading(false);
    UploadService.getFiles().then((response) => {
      //const col= response.data;
  
      console.log("response.data", response.data);
     // setImageInfos((col) => col.filter((x) => getExtension(x.name) !== "pdf"));
      setImageInfos(response.data);
      setImageInfos((col) => col.filter((x) => getExtension(x.name) !== "pdf"));
      setLoading(true);
    });
  }, []);

  const selectFiles = (event) => {
    let images = [];

    for (let i = 0; i < event.target.files.length; i++) {
      images.push(URL.createObjectURL(event.target.files[i]));
    }

    setSelectedFiles(event.target.files);
    setImagePreviews(images);
    setProgressInfos({ val: [] });
    setMessage([]);
  };

  const upload = (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
    })
      .then(() => {
        setMessage((prevMessage) => [
          ...prevMessage,
          "Imaginea a fost încărcată cu succes: " + file.name,
        ]);
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });

        setMessage((prevMessage) => [
          ...prevMessage,
          "Nu s-a putut încărca imaginea: " + file.name,
        ]);
      });
  };

  const uploadImages = () => {
    const files = Array.from(selectedFiles);

    let _progressInfos = files.map((file) => ({
      percentage: 0,
      fileName: file.name,
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = files.map((file, i) => upload(i, file));
    setLoading(false);
    Promise.all(uploadPromises)
      .then(() => UploadService.getFiles())
      .then((files) => {
        setImageInfos(files.data);
        setLoading(true);
      });

    setMessage([]);
  };

  const deletePoza = async (name) => {
    //if (window.confirm("Stergi imaginea? "+name))
    //{

    ////window.alert(name);
    //console.log("sterge "+name);
    // history("/");
    //let _progressInfos = [...progressInfosRef.current.val];
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv imaginea ${name}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
        UploadService.deleteimg(name, (event) => {})
          .then(() => {
            setMessage((prevMessage) => [
              ...prevMessage,
              "Imaginea a fost stearsa cu succes: " + name,
            ]);
            setLoading(false);
            UploadService.getFiles().then((response) => {
              setImageInfos(response.data);
              setLoading(true);
            });
          })
          .catch(() => {
            //  _progressInfos[10].percentage = 0;
            //  setProgressInfos({ val: _progressInfos });

            setMessage((prevMessage) => [
              ...prevMessage,
              "Nu s-a putut sterge imaginea: " + name,
            ]);
          });
        //}
      })
      .catch(() => {
        //console.log("Deletion cancelled.")
      });
  };

  const handleSel = (valtrimite) => {
    // const values = [...itemList];
    // values[index].value = e.target.value;
    // setSelList(valtrimite);
    onChange(valtrimite);
  };
  return (
    <Box m="20px">
      <div className="row">
        <div className="col-8">
          {/* <label className="btn btn-default p-0"> */}
          <input
            type="file"
            multiple
            accept="image/*, application/pdf"
            onChange={selectFiles}
          />
          {/* </label>*/}
        </div>

        <div className="col-4">
          <button
            className="btn btn-success btn-sm"
            disabled={!selectedFiles}
            onClick={uploadImages}
          >
            Upload
          </button>
        </div>
      </div>

      {progressInfos &&
        progressInfos.val.length > 0 &&
        progressInfos.val.map((progressInfo, index) => (
          <div className="mb-2" key={index}>
            <span>{progressInfo.fileName}</span>
            <div className="progress">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                aria-valuenow={progressInfo.percentage}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progressInfo.percentage + "%" }}
              >
                {progressInfo.percentage}%
              </div>
            </div>
          </div>
        ))}

      {imagePreviews.length > 0 && (
        <div>
          {imagePreviews.map((img, i) => {
            return (
              <img
                className="preview"
                src={img}
                alt={"image-" + i}
                key={i}
                height="200px"
              />
            );
          })}
        </div>
      )}

      {message.length > 0 && (
        <div className="alert alert-secondary mt-2" role="alert">
          <ul>
            {message.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
        </div>
      )}

      {loading && (
        <ImgBibl
          defaultList={defaultList}
          ImgData={imageInfos}
          onModal={handleEventClick}
          Onsterg={deletePoza}
          onChange={handleSel}
        />
      )}
      {/* {imageInfos.length && (
        <>
        <div className="card mt-3">
          <div className="card-header">Lista de imagini</div>
          <ul className="list-group list-group-flush">
            {imageInfos &&
              imageInfos.map((img, index) => (
                <li className="list-group-item" key={index}>
                  
                    <a href={img.url}>{img.name}</a>
             <button className="badge badge-danger mr-2 ml-3 pull-right" onClick={ () => deletePoza(img.name) } >
              <span><i className="fa fa-trash" aria-hidden="true"></i></span>
            </button>
                 
<div onClick={ () => handleEventClick(img.url) }>
                  <img src={img.url} alt={img.name} height="80px" /></div>
                </li>
              ))}
          </ul>
        </div>
        <ImgCol ImgData={imageInfos} onModal={handleEventClick} Onsterg={deletePoza} />
        </>

      )}
      */}

      {modalOpen && (
        <Modal
          isOpen={true}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={customModalStyles}
        >
          <CloseButton onClick={closeModal}>X</CloseButton>
          {<Imgligth img={selectedLight} />}
        </Modal>
      )}
    </Box>
  );
};

export default BiblImg;
