import { fetchWrapper, history } from '../_helpers';
import http from "../http-common";

const getAll = () => {
  return http.get(`/project/list`);
};
const getAllactiv = () => {
  return http.get(`/project/activ`);
};
const get = id => {
  return http.get(`/project/id/${id}`);
};


const getbyslug = slug => {
  return http.get(`/project/slug/${slug}`);
};


const create = data => {
  return http.post(`/project`, data);
};

const update = (id, data) => {
	//console.log(id);
	 return http.put(`/project/${id}`, data);
 // return http.put("/project/${id}", data);
};

const remove = id => {
  return http.delete(`/project/${id}`);
};



const ProjectService = {
  getAll,
  getAllactiv,
  get,
  create,
  update,
  remove,
  getbyslug
};

//export default PageService;
export {ProjectService};