import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ConfirmProvider } from "material-ui-confirm";
import Topbar from "./pages/global/Topbar";

import Dashboard from "./pages/dashboard";
import Team from "./pages/team";
import Invoices from "./pages/invoices";
import Contacts from "./pages/contacts";
import Form from "./pages/form";
import Calendar from "./pages/calendar";
import Bar from "./pages/bar";
import Line from "./pages/line";
import Pie from "./pages/pie";
import FAQ from "./pages/faq";
import Geography from "./pages/geography";
import Page404 from "./pages/Page404";
import { Home } from "./home";
import { PrivateRoute } from "_components";
import { Login } from "login";
import { Profile } from "profile";
import { Update } from "profile/Update";
import { Admin } from "admin";
import { Users } from "./admin/users";
import { Posts } from "./admin/posts";
import { CategoriesList, WriteCategories } from "./admin/categories";
import { ProductsList, WriteProducts } from "./admin/products";
import { DoctorList, WriteDoctor, Functii } from "./admin/doctor";
import { ServiciiList, WriteServicii } from "./admin/servicii";
import { SeoList, WriteSeo } from "./admin/seo";
import { ProjectList, WriteProject } from "./admin/project";
import { PagesList } from "./admin/pages/PagesList";
import { Page } from "./admin/pages/Page";
import { AddPage } from "./admin/pages/AddPage";
import { FaqList } from "./admin/faq/FaqList";
import { Faq } from "./admin/faq/Faq";
import { AddFaq } from "./admin/faq/AddFaq";
import { Write } from "./admin/posts/write";
import { WriteUser } from "./admin/users/writeuser";
import { Register, VerifyEmail, ForgotPassword, ResetPassword } from "account";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "./_helpers";
import { Role } from "./_helpers";

const App = () => {
  const [theme, colorMode] = useMode();
  const { pathname } = useLocation();
  history.navigate = useNavigate();
  history.location = useLocation();
  return (
    <ConfirmProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MyProSidebarProvider>
            <div style={{ height: "100%", width: "100%" }}>
              <main>
                <Topbar />
                <ToastContainer />
                <Routes>
                  <Route path="/Dashboard" element={<Dashboard />} />
                  <Route path="/doctors" element={<Team />} />
                  <Route path="/contacts" element={<Contacts />} />
                  <Route path="/invoices" element={<Invoices />} />
                  <Route path="/form" element={<Form />} />
                  <Route path="/bar" element={<Bar />} />
                  <Route path="/pie" element={<Pie />} />
                  <Route path="/line" element={<Line />} />
                  <Route path="/help" element={<FAQ />} />
                  <Route path="/programari" element={<Calendar />} />
                  <Route path="/geography" element={<Geography />} />
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Home />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/orders"
                    element={
                      <PrivateRoute>
                        <Page404 />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/servicii"
                    element={
                      <PrivateRoute>
                        <ServiciiList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/servicii/write"
                    element={
                      <PrivateRoute>
                        <WriteServicii />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/seo"
                    element={
                      <PrivateRoute>
                        <SeoList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/seo/write"
                    element={
                      <PrivateRoute>
                        <WriteSeo />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/project"
                    element={
                      <PrivateRoute>
                        <ProjectList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/project/write"
                    element={
                      <PrivateRoute>
                        <WriteProject />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/position"
                    element={
                      <PrivateRoute>
                        <Functii />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/clients"
                    element={
                      <PrivateRoute>
                        <Page404 />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/products"
                    element={
                      <PrivateRoute>
                        <ProductsList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/products/write"
                    element={
                      <PrivateRoute>
                        <WriteProducts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/team"
                    element={
                      <PrivateRoute>
                        <DoctorList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/team/write"
                    element={
                      <PrivateRoute>
                        <WriteDoctor />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/categories"
                    element={
                      <PrivateRoute>
                        <CategoriesList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/categories/write"
                    element={
                      <PrivateRoute>
                        <WriteCategories />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/sitemap"
                    element={
                      <PrivateRoute>
                        <Page404 />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/profile/update"
                    element={
                      <PrivateRoute>
                        <Update />
                      </PrivateRoute>
                    }
                  />
                  <Route path="/register" element={<Register />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route
                    path="/account/reset-password"
                    element={<ResetPassword />}
                  />
                  <Route
                    path="/account/verify-email"
                    element={<VerifyEmail />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<Navigate to="/" />} />
                  <Route
                    path="/admin"
                    element={
                      <PrivateRoute>
                        <Admin />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={
                      <PrivateRoute>
                        <Users />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/posts"
                    element={
                      <PrivateRoute>
                        <Posts />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/posts/write"
                    element={
                      <PrivateRoute>
                        <Write />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/users/write"
                    element={
                      <PrivateRoute>
                        <WriteUser />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/pages"
                    element={
                      <PrivateRoute>
                        <PagesList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/Pages"
                    element={
                      <PrivateRoute>
                        <AddPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/Pages/:id"
                    element={
                      <PrivateRoute>
                        <Page />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin/faq"
                    element={
                      <PrivateRoute>
                        <FaqList />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/Faqs"
                    element={
                      <PrivateRoute>
                        <AddFaq />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/Faq/:id"
                    element={
                      <PrivateRoute>
                        <Faq />
                      </PrivateRoute>
                    }
                  />
                </Routes>
              </main>
              <div className="footer text-center mt-4 bg-secondary text-white">
                <p>
                  © Copyright 2024&nbsp;|&nbsp;Website official{" "}
                  <a href="https://mysecretbyaida.ro/" target="_blank">
                    My Secret By Aida.
                  </a>
                  &nbsp;|&nbsp;Toate drepturile rezervate&nbsp;|&nbsp;
                </p>
              </div>
            </div>
          </MyProSidebarProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </ConfirmProvider>
  );
};

export default App;
