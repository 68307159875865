import React, { useEffect, useState, useRef } from "react";

import { Link, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//import axios from "axios";
//import http from "../../http-common";
import UploadService from "../../_services/FileUploadService";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { authActions } from "_store";
import { DoctorService, alertService, accountService } from "../../_services";
import { doctorActions } from "../../_store";
import AutoSelect from "../../_helpers/autoselect";

import Select, { OptionsType, ValueType } from "react-select";
import { Options, OnChangeValue } from "react-select";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import slugify from "react-slugify";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import Program from "./Program";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { CloseButton } from "../../components/styles/CalendarStyles";
import Modal from "react-modal";

const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
    data.map((item, key) => {
      arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
      return null;
    });
  }
  return arrayItem;
};

const WriteDoctor = () => {
  const state = useLocation().state;
  const navigate = useNavigate();
  const [id, setId] = useState(state?.id || 0);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [value, setValue] = useState(state?.description || "");
  const [description, setDescription] = useState(state?.description || "");
  const [firstName, setFirstName] = useState(state?.firstName || "");
  const [active, setActive] = useState(state?.active || false);
  const [isGeneralist, setIsGeneralist] = useState(
    state?.isGeneralist || false
  );
  const [lastName, setLastName] = useState(state?.lastName || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState(state?.img || "");
  // const [price, setPrice] = useState(state?.price || 0);
  // const [positionId, setPositionId] = useState(Arrays(state?.Position , "title", "id")|| []);
  const [positionId, setPositionId] = useState(state?.PositionId || "");
  const [userId, setUserId] = useState(state?.UserId || "");
  //const [userId, setUserId] = useState(Arrays(state?.User , "username", "id")|| []);
  const [allmain, setAllmain] = useState(null);
  const [allcateg, setAllcateg] = useState(null);
  const [alldoctor, setAlldoctor] = useState(null);
  const [users, setUsers] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState("");
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 1000 },
  };
  const openForm = () => {
    setSelectedModal("Program");
    openModal();
  };

  // Open Modal Function
  const openModal = () => {
    setModalOpen(true);
  };

  // Close Modal Function
  const closeModal = () => {
    setModalOpen(false);
  };

  const isAddMode = !id;
  const authUser = useSelector((x) => x.auth.user);
  const initialValues = {
    firstName: firstName,
    lastName: lastName,
    description: value,
    img: pimg,
    isGeneralist: isGeneralist,
    active: active,
    PositionId: positionId,
    UserId: userId,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    DoctorService.getAllposition().then((x) => setAllmain(x.data));
    accountService.getAll().then((x) => setUsers(x));
  }, []);
  //const { categories } = useSelector(x => x.categories);

  //const { categories } = useSelector(x => x.categories);
  //if(categories) {
  //  setAllmain(categories);
  //}
  //console.log(allmain);
  if (allmain && !allcateg) {
    console.log("Functii:", allmain);
    setAllcateg(Arrays(allmain, "title", "id"));
    console.log("pos:", allcateg);
  }
  if (users && !alldoctor) {
    //console.log(allmain.data);
    console.log(users);
    setAlldoctor(Arrays(users, "username", "id"));
    console.log(alldoctor);
    //console.log(allcateg);
  }
  //   //console.log(categories);
  //useSelector(x => x.categories);
  ////console.log(categ );

  const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.upload(file);
      // setPimg(res.data.message]);
      //console.log(res.data.message);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  async function loadimg() {
    if (file) {
      const imgUrl = await upload();
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
    // //window.alert(imagePreviews);
    //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Nume este obligatoriu"),
    PositionId: Yup.number().required("Functie este obligatorie"),
    UserId: Yup.number().required("Utilizator asociat este obligatorie"),
    myfile: Yup.mixed().test(
      "2097152",
      "Fișierul încărcat este prea mare.",
      (value) => {
        return value && filesharhe_ref.current
          ? filesharhe_ref.current.files[0].size <= 2097152
            ? true
            : false
          : true;
      }
    ),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    loadimg();

    console.log(fields);

    fields["description"] = value;
    fields["img"] = pimg;
    fields["active"] = active;
    fields["isGeneralist"] = isGeneralist;
    // window.alert(JSON.stringify({ fields}));
    // console.log(Object.values(PositionId)[1]);
    // console.log(Object.values(UserId)[1]);
    //const Pid = PositionId.map((e) => e.value);
    //console.log(Pid );

    // fields["slug"] = slugify(fields["title"]);
    //window.alert(JSON.stringify({ fields }));
    if (isAddMode) {
      createDoctor(fields, setSubmitting);
    } else {
      updateDoctor(id, fields, setSubmitting);
    }
  }

  function createDoctor(fields, setSubmitting) {
    DoctorService.create(fields)
      .then(() => {
        toast.success("Doctor adăugat cu succes");
        alertService.success("Produs adăugat cu succes", {
          keepAfterRouteChange: true,
        });

        navigate("/team");
      })
      .catch((error) => {
        setSubmitting(false);
        //console.log(error);
        toast.error(error.response.data.message);

        alertService.error(error);
      });
  }

  function updateDoctor(id, fields, setSubmitting) {
    //console.log("Update"+id);
    DoctorService.update(id, fields)
      .then(() => {
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        toast.success("Actualizare cu succes", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/team");
      })
      .catch((error) => {
        setSubmitting(false);
        //console.log(error.response.data.message);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Box m="20px">
            <Form>
              <h1>
                {isAddMode ? "Adaugă Membru Echipa" : "Editează  Membru Echipa"}
              </h1>
              {isAddMode ? (
                ""
              ) : (
                <Button
                  color="secondary"
                  startIcon={<AddIcon />}
                  onClick={openForm}
                >
                  Program de lucru
                </Button>
              )}
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Nume</label>
                  <Field
                    name="firstName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.firstName && touched.firstName
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="firstName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Prenume</label>
                  <Field
                    name="lastName"
                    type="text"
                    className={
                      "form-control" +
                      (errors.lastName && touched.lastName ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="lastName"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-3">
                  <label>
                    Status{" "}
                    {active ? (
                      <div className="badge badge-primary">Activ</div>
                    ) : (
                      <div className="badge badge-danger">Inactiv</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="active"
                      onChange={(e) => setActive(!active)}
                      className={
                        "form-control d-none" +
                        (errors.active && touched.active ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="active"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>
                <div className="form-group col-3">
                  <label>
                    Generalist{" "}
                    {isGeneralist ? (
                      <div className="badge badge-primary">Da</div>
                    ) : (
                      <div className="badge badge-danger">Nu</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="isGeneralist"
                      onChange={(e) => setIsGeneralist(!isGeneralist)}
                      className={
                        "form-control d-none" +
                        (errors.isGeneralist && touched.isGeneralist
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="isGeneralist"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>

                <div className="form-group col-6">
                  <small>
                    Sunt acceptate poze doar cu extensia jpg, gif, png, webp sub
                    2Mb. Rezolutie recomandata 800x400
                  </small>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col">
                  <div className="editorContainer">
                    <label>Descriere</label>
                    <Field
                      name="description"
                      value={value}
                      type="hidden"
                      className={
                        "form-control" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ReactQuill
                      className="editor"
                      theme="snow"
                      value={value}
                      onChange={setValue}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-8">
                  <label></label>
                  <Field
                    name="image"
                    type="hidden"
                    value={pimg}
                    className={
                      "form-control" +
                      (errors.img && touched.img ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="invalid-feedback"
                  />

                  <label className="file btn btn-sm btn-info" htmlFor="file">
                    Incarca imaginea
                  </label>

                  <Field
                    innerRef={filesharhe_ref}
                    type="file"
                    id="file"
                    name="myfile"
                    onChange={handleClick}
                    accept=".jpg, .png, .gif, .svg, .webp"
                  />
                  <ErrorMessage name="myfile" />
                  {pimg && !file && (
                    <img
                      src={
                        process.env.REACT_APP_API_URL + "/test/files/" + pimg
                      }
                      alt={pimg}
                      height="30vh"
                      align="center"
                      className="img-fluid img-responsive"
                    />
                  )}

                  {imagePreviews && (
                    <>
                      {imagePreviews.map((img, i) => {
                        return (
                          <img
                            src={img}
                            alt={"image-" + i}
                            key={i}
                            height="80px"
                            align="center"
                            className="img-fluid img-responsive"
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label>Functie</label>
                  <Field
                    name="PositionId"
                    as="select"
                    className={
                      "form-control" +
                      (errors.PositionId && touched.PositionId
                        ? " is-invalid"
                        : "")
                    }
                  >
                    <option value=""></option>
                    {allcateg && (
                      <>
                        {allcateg.map((pos) => {
                          return (
                            <option value={pos.value} key={pos.value}>
                              {pos.label}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </Field>

                  <ErrorMessage
                    name="PositionId"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group col-6">
                  <label>Utilizator asociat</label>
                  <Field
                    name="UserId"
                    as="select"
                    className={
                      "form-control" +
                      (errors.UserId && touched.UserId ? " is-invalid" : "")
                    }
                  >
                    <option value=""></option>
                    {alldoctor && (
                      <>
                        {alldoctor.map((pos) => {
                          return (
                            <option value={pos.value} key={pos.value}>
                              {pos.label}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </Field>

                  <ErrorMessage
                    name="UserId"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-group">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Salvează
                </button>
                <Link
                  to={isAddMode ? "/team" : "/team"}
                  className="btn btn-link"
                >
                  Abandon
                </Link>
              </div>
            </Form>
            {modalOpen && (
              <Modal
                isOpen={true}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={customModalStyles}
              >
                <CloseButton onClick={closeModal}>X</CloseButton>
                {<Program doctorid={id} />}
              </Modal>
            )}
          </Box>
        );
      }}
    </Formik>
  );
};

export { WriteDoctor };
