import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { Container, InputAdornment, TextField } from "@mui/material";
import Drag from "./Drag";

export default function ImgBibl({
  defaultList,
  onChange,
  ImgData,
  onModal,
  Onsterg
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [itemData, setItemData] = useState(ImgData);
  const [allData, setIAllData] = useState(ImgData);
  const [searchTerm, setSearchTerm] = useState("");
  console.log("def imgbibl : ",defaultList);
  console.log("All imgbibl : ",allData, ImgData);
  const [selList, setSelList] = useState(defaultList || []); 
  //if (defaultList.length > 0) {setSelList(JSON.parse(defaultList))};

  console.log("Ml : ", selList.length, selList);
  const handleChange = (e) => {
    // setSearchTerm(event.target.value);
    setSearchTerm(e.target.value ? e.target.value : "");
    const search = e.target.value ? e.target.value : "";
    console.log("search : ", searchTerm, search);
    setItemData(
      allData.filter((item, index) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  };

  const handleChangeSel = (valuesel) => {
    // const values = [...itemList];
    // values[index].value = e.target.value;
    setSelList(valuesel);
    //onChange(valuesel);
    /* const values = [...itemData];
     for (let index = 0; index < values.length; index++) {
      values[`${index}`]['status'] = false;
      console.log("values :", index, values[`${index}`]['status']);
    }
   
    valuesel.map((item) => ( 
      //const v1 = allData.findIndex((element) => element.img === item),
      values[`${allData.findIndex((element) => element.img === item)}`]['status'] = true

     ));
     //values[`${allData.findIndex((element) => element.img === item)}`]['status'] = false;
     setItemData(values)*/
    //console.log("id",e.target.id, e.target.type  );
  };

  const handleTrimite = (valtrimite) => {
    // const values = [...itemList];
    // values[index].value = e.target.value;
    setSelList(valtrimite);
    //onChange(valtrimite);
    onChange(valtrimite);
  };

  const handleDeleteField = (e, index) => {
    const values = [...allData];
    Onsterg(index);
    values[`${allData.findIndex((element) => element.img === index)}`][
      "status"
    ] = false;
    //console.log("e este : ", index, e)
    // values.splice(allData.findIndex((element) => element.img === index), 1);
    setIAllData(values);
    // setItemData(values);
    //setItemData(values.filter((item,index) => {
    // return (
    //     item.title.toLowerCase().includes(searchTerm.toLowerCase())
    //)
    //}));
  };

  const handleDeleteSel = (index) => {
    const values = [...allData];
    console.log("e este : ", index);
    //values.splice(allData.findIndex((element) => element.img === index), 1);
    values[`${allData.findIndex((element) => element.name === index)}`][
      "status"
    ] = false;
    setIAllData(values);
    //setItemData(values);
    setItemData(
      values.filter((item, index) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
    // handleChange(searchTerm);
  };
  useEffect(() => {
    if (selList.length > 0) {
      const values = [...allData];
      console.log("More: ", selList, values);
      selList.map(
        (item) => {
        if (allData.findIndex((element) => element.name === item)) {
          //const v1 = allData.findIndex((element) => element.img === item),
          values[`${allData.findIndex((element) => element.name === item)}`][
            "status"
          ] = true;
        }
        }
      );
      //values[`${e}`]['status'] = true;

      //values[index].value = e.target.value;
      setItemData(values);
    }
    // const nume = getItem("userName");
    //  console.log("User name : ",nume);
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  // console.log(itemData[0]);

  const handleClickOpen = (e) => {
    setCurrentImage(e);
    console.log("index", e);
    console.log(`current index is ${currentImage}`);
    console.log(itemData[`${currentImage}`]);
    const values = [...itemData];
    const imvalue = [...selList];
    //values[`${e}`]['status'] = true;
    values[`${e}`]["status"] = !values[`${e}`]["status"];
    let im = selList.indexOf("values[`${e}`]['name']");
    if (values[`${e}`]["status"]) {
      if (im === -1) {
        imvalue.push(values[`${e}`]["name"]);
      }
    } else {
      imvalue.splice(im, 1);
    }
    setSelList(imvalue);
    //values[index].value = e.target.value;
    setItemData(values);
    console.log("MI:", itemData[`${currentImage}`]);
    const count = values.filter((item) => item.status === true).length;
    console.log("Selectate", count);
    //  itemData[`${currentImage}`]['status'] = true;
    // setOpen(true);
  };

  // sx={{ width: 600, height: 600 }}
  /*sx={{
    columnCount: {
      xs: '1 !important',
      sm: '2 !important',
      md: '3 !important',
      lg: '4 !important',
      xl: '5 !important',
    },
  }} cols={3} gap={18}
  
  spacing={80} justify="center"
  
  */

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="md" sx={{ mt: 20 }}>
        <TextField
          id="search"
          type="search"
          label="Cauta"
          value={searchTerm}
          onChange={handleChange}
          sx={{ width: 360 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <Grid container spacing={2}>
        <Grid item xs={8} key='1'>
          <Item>
            <ImageList
              gap={10}
              sx={{
                gridTemplateColumns:
                  "repeat(auto-fill, minmax(200px, 1fr))!important",
              }}
            >
              {itemData.map((item, index) => (
                <ImageListItem
                  key={item.img}
                  style={item.status ? { border: "5px solid green" } : {}}
                >
                  <img
                    src={`${item.url}?w=124&fit=crop&auto=format`}
                    srcSet={`${item.url}?w=124&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.name}
                    position="below"
                    actionIcon={
                      <>
                         <IconButton
                          onClick={(e) => onModal(item.url)}
                          sx={{ color: "rgba(0, 255, 255, 0.3)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => handleClickOpen(index)}
                          sx={{ color: "rgba(0, 255, 0, 0.3)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <DoneOutlineIcon />
                        </IconButton>

                      </>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Item>
        </Grid>

        <Grid item xs={4} key='2'>
          <Item>
            <Drag
              defaultList={selList}
              onChange={handleChangeSel}
              onSterg={handleDeleteSel}
              onTrimite={handleTrimite}
            />
          </Item>
        </Grid>
        {/*   <Grid item xs={4}>
          <Item>xs=4</Item>
        </Grid>
        <Grid item xs={8}>
          <Item>xs=8</Item>
        </Grid> */}
      </Grid>
    </Box>
  );
}


