import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';
import { accountService } from '../../_services';
import { calculateRange, sliceData } from '../../_services/table-pagination';
import DOMPurify from "dompurify";
import { Box } from '@mui/material';
import Header from "../../components/Header";
import { toast } from "react-toastify";


function List({ match }) {
  const { path } = useLocation();// match;
  const [posts, setPosts] = useState(null);
  const [allPosts, setAllPosts] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState([]);
  const [search, setSearch] = useState('');

  //   const { cucu } = accountService.userValue;
  const authUser = useSelector(x => x.auth.user);
  //console.log(authUser?.id);
  useEffect(() => {
    accountService.getpostsAll().then(x => setPosts(x));
    accountService.getpostsAll().then(x => setAllPosts(x));
    //let search_results = posts;
    //     //window.alert((JSON.stringify({posts})));
    //   setAllPosts(posts);
    // setPosts(allPosts);
    //setAllPosts(search_results);
    if (posts) {
      //  setAllPosts(posts);
      //console.log(allPosts);
      //  //window.alert((JSON.stringify({posts})));
      setPagination(calculateRange(allPosts, 5));
      setPosts(sliceData(allPosts, page, 5));
    }
  }, []);


  // Search
  const __handleSearch = (event) => {
    setSearch(event.target.value);
    if (event.target.value !== '') {
      let search_results = allPosts.filter((item) =>
        item.title.toLowerCase().includes(search.toLowerCase())
      );
      setPosts(sliceData(search_results, page, 5));
      setPagination(calculateRange(search_results, 5));
    }
    else {
      setPagination(calculateRange(posts, 5));
      __handleChangePage(1);
    }
  };

  // Change Page 
  const __handleChangePage = (new_page) => {
    setPage(new_page);
    setPosts(sliceData(allPosts, new_page, 5));
  }

  const setActivePage = (Page, index) => {
    setCurrentPage(Page);
    setCurrentIndex(index);
  };


  function deletePost(id) {
    if (window.confirm("Stergi postarea? " + id)) {
      setPosts(posts.map(x => {
        if (x.id === id) { x.isDeleting = true; }
        return x;
      }));
      accountService.deleteposts(id).then(() => {
        setPosts(posts => posts.filter(x => x.id !== id));
        toast.success("Articolul a fost sters cu succes");
      });
    }
  }

  return (
    <Box m="20px">
    <Header title="Lista de articole" subtitle="Vă rugăm să faceți clic titlu..." />
    <div>

      <p>Toate postările de la punctul final api securizat (numai pentru administrator).:</p>
      <div className='input-group mb-3'>
        <input
          type='text'
          value={search}
          placeholder='Cauta..'
          className='dashboard-content-input'
          onChange={e => __handleSearch(e)} />
      </div>
      <Link to={`/admin/posts/write`} className="btn btn-sm btn-success mb-2">Adaugă postare</Link>
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: '30%' }}>Titlu</th>
            <th style={{ width: '30%' }}>Slug</th>
            <th style={{ width: '30%' }}>Categorie</th>
            <th style={{ width: '10%' }}></th>
          </tr>
        </thead>
        <tbody>
          {posts && posts.map(post =>
            <tr key={post.id}>
              <td >  <Link className="badge badge-info" onClick={() => setActivePage(post, post.id)}>{post.title}</Link></td>
              <td>{post.slug}</td>
              <td>{post.categoryId}</td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <Link to={`/admin/posts/write`} state={post} className="btn btn-sm btn-primary mr-1"><i className="fas fa-edit"></i></Link>
                <button onClick={() => deletePost(post.id)} className="btn btn-sm btn-danger" style={{ width: '40px' }} disabled={post.isDeleting}>
                  {post.isDeleting
                    ? <span className="spinner-border spinner-border-sm"></span>
                    : <span><i className="fa fa-trash" aria-hidden="true"></i></span>
                  }
                </button>
              </td>
            </tr>
          )}
          {!posts &&
            <tr>
              <td colSpan="4" className="text-center">
                <span className="spinner-border spinner-border-lg align-center"></span>
              </td>
            </tr>
          }
        </tbody>
      </table>

      <div className="col-md-6">
        {currentPage ? (
          <div>
            <h4>Articol</h4>
            <div>
              <label>
                <strong>Titlu:</strong>
              </label>{" "}
              {currentPage.title}
            </div>
	                <div>
              <label>
               
              </label>{" "}
	       <img src={process.env.REACT_APP_API_URL + "/test/files/" + currentPage.img} className="img-responsive img-fluid" width="100%"/>
            </div>
            <div>
              <label>
                <strong>Descriere:</strong>
              </label>{" "}
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentPage.description),
                }}
              ></p>
            </div>
            <div>
              <label>
                <strong>Status:</strong>
              </label>{" "}
              {currentPage.published ? "Publicat" : "Pending"}
            </div>



            <Link className="badge badge-warning" to={`/admin/posts/write`} state={currentPage} ><i className="fas fa-edit"></i></Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Vă rugăm să faceți clic pe un titlu...</p>
          </div>
        )}
      </div>

    </div>
   </Box>
  );
}

export { List };