import './widget.scss'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Link} from 'react-router-dom';

const Widget = ({type, stats}) => {

    let data;
console.log("widet", stats);
const {artists, uall, aproject, allproject, aservicii, allservicii, apage, allpage} = stats;
console.log(artists, uall, aproject, allproject, aservicii, allservicii, apage, allpage);
    // temporary amount of money
    const amount = Math.floor(Math.random() * 100);
    const diff = Math.floor(Math.random() * 100);

    switch (type) {
        case 'pagini':
            data = {
                title: 'PAGINI',
                isMoney: false,
                amount: apage+'/'+allpage,
                link: 'Vedeți toate paginile',
                url:'/admin/pages',
                icon: (
                    <PersonOutlinedIcon className='icon' style={{
                        color: 'crimson', 
                        backgroundColor: 'rgba(255, 0, 0, 0.2)'}} />
                ),
            }
            break;
            case 'proiecte':
                data = {
                    title: 'ARTICOLE',
                    isMoney: false,
                    amount: aproject+'/'+allproject,
                    link: 'Vedeți toate articolele',
                    url:'/project',
                    icon: (
                        <ShoppingCartOutlinedIcon className='icon' style={{
                            color: 'goldenrod', 
                            backgroundColor: 'rgba(218, 65, 32, 0.2)'}} />
                    ),
                }
                break;
                case 'servicii':
                    data = {
                        title: 'SERVICII',
                        isMoney: false,
                        amount: aservicii+'/'+allservicii,
                        link: 'Vedeți toate serviciile',
                        url:'/servicii',
                        icon: (
                            <MonetizationOnOutlinedIcon className='icon'  style={{
                                color: 'green', 
                                backgroundColor: 'rgba(0, 128, 0, 0.2)'}} />
                        ),
                    }
                    break;
        case 'users':
            data = {
                title: 'UTILIZATORI',
                isMoney: false,
                amount: artists+'/'+uall,
                link: 'Vedeți toți utilizatori',
                url:'/admin/users',
                icon: (
                    <AccountBalanceWalletOutlinedIcon className='icon' style={{
                        color: 'purple', 
                        backgroundColor: 'rgba(128, 0, 128, 0.2)'}} />
                ),
            }
            break;
        case 'orders':
            data = {
                title: 'LUCRARI',
                isMoney: false,
                amount: amount,
                link: 'Vedeți toate lucrarile',
                url:'/admin/pages',
                icon: (
                    <ShoppingCartOutlinedIcon className='icon' style={{
                        color: 'goldenrod', 
                        backgroundColor: 'rgba(218, 65, 32, 0.2)'}} />
                ),
            }
            break;
        case 'earnings':
            data = {
                title: 'CÂŞTIGURI',
                isMoney: true,
                amount: amount,
                link: 'Vedeți câștigurile',
                url:'/admin/pages',
                icon: (
                    <MonetizationOnOutlinedIcon className='icon'  style={{
                        color: 'green', 
                        backgroundColor: 'rgba(0, 128, 0, 0.2)'}} />
                ),
            }
            break;
        case 'balance':
            data = {
                title: 'BILANT',
                isMoney: true,
                amount: amount,
                link: 'Vezi detalii',
                url:'/admin/pages',
                icon: (
                    <AccountBalanceWalletOutlinedIcon className='icon' style={{
                        color: 'purple', 
                        backgroundColor: 'rgba(128, 0, 128, 0.2)'}} />
                ),
            }
            break;
        default:
            break;
    }



  return (
    <div className='widget'>
        <div className='left'>
            <span className='title'>{data.title}</span>
            <span className='counter'>{data.amount}{data.isMoney && '00 Lei'} </span>
            <Link to={data.url} className='link'>{data.link}</Link>
        </div>
        <div className='right'>
            <div className="percentage positive">
                <KeyboardArrowUpIcon />
                {diff}%
            </div>
                {data.icon}
        </div>
    </div>
  )
}

export default Widget