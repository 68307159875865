import React, { useState, useEffect } from "react";
import "./drag.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

function Drag({defaultList, onChange, onSterg, onTrimite} ) {
  console.log("def",defaultList);
  const [itemList, setItemList] = useState(defaultList); 
console.log(itemList);
useEffect(() => {
  //ProjectService.getAllposition().then(x => setAllmain(x.data));
  setItemList(defaultList);

}, []);
  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
    onChange(updatedList);
   //onChange(updatedList);
    console.log(itemList);
  };
  const deleteField = (e, index) => {
    const val = [...itemList];
    console.log("Drag index :",itemList[`${index}`]);
   // values[`${allData.findIndex((element) => element.img === index)}`]
   val.splice(index, 1);
    onSterg(itemList[`${index}`]);
    
    
    setItemList(val);
  onChange(val);
  };

  return (
    <>
    <Button variant="contained" color="success" endIcon={<SendIcon />}
  onClick={() => {
   // alert('clicked');
    onTrimite(itemList);
  }}
>
  Trimite {itemList.length} poze
</Button>
    <div className="Drag">
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {itemList.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <div
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div className="flex container items-center">

                      <img src={process.env.REACT_APP_API_URL + "/products/image/" +item} width="100px" align="left" />
                     {/*  <button onClick={(e) => deleteField(e, index)} className="imput">X</button>*/}
                     <IconButton
                  onClick={(e) => deleteField(e, index)}
                  sx={{ color: "rgba(255, 0, 0, 0.54)" }}
                  aria-label={`info about ${item.title}`}
                >
                  <CloseIcon />
                </IconButton>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
    </>
  );
}

export default Drag;