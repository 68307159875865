//import Chart from '../../components/chart/Chart'
import React, { useState, useEffect } from 'react';
import Widget from '../components/widget/Widget'
import Feature from '../components/feature/Feature'
import { accountService } from '../_services'
//import { userData } from '../../dummyData'
import './home.scss'
export { Plans };
const Plans = () => {
  const [stats, setStats] = useState(null);
  useEffect(() => {
    (async () => {
      await accountService.getStats().then(res => {
        console.log(res);
        if (res !== undefined) {
          setStats(res);
          
          //  setSquarePo(res.data.squareP);
           // setRezP(res.data.rezpicsells);
          //  setPicsP(res.data.picsells);

        }
    });
    })();
  // const data =  accountService.getStats()
   //.then(x => setStats(x));
    //console.log(usergetStats);

 }, []);
 console.log("stats", stats);
  return (
  <div className="home">
 <div className="homeContainer">

 {stats && <div className='widgets'>
            <Widget type='pagini' stats={stats} />
            <Widget type='proiecte' stats={stats}/>
            <Widget type='servicii' stats={stats}/>
            <Widget type='users' stats={stats}/>
           {/* <Widget type='orders' stats={stats}/>
            <Widget type='earnings' stats={stats}/>
            <Widget type='balance' stats={stats}/> */}
          </div>  }
	       {/*     <div className="charts">
            <Feature />
 
          </div>  */}
	  </div>
    </div>
  )
}

//export default Plans