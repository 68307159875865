import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import {
  retrieveFaqs,
  findFaqsByTitle,
  deleteAllFaqs,
  deleteFaq,
} from "../../_store/faqs";
import { FaqService } from "../../_services/FaqService";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";

const FaqList = () => {
  const [currentFaq, setCurrentFaq] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchTitle, setSearchTitle] = useState("");
  const [allPosts, setAllPosts] = useState(null);
  const [faqs, setFaqs] = useState(null);
  useEffect(() => {
    FaqService.getAll().then((x) => setFaqs(x));
  }, []);

  const dispatch = useDispatch();
  const Faqs = useSelector((state) => state.Faqs);

  ////window.alert(JSON.stringify({faqs}));
  const onChangeSearchTitle = (e) => {
    //refreshData();
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    /*if (event.target.value !== '') {
   const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
	console.log("searchTitle : ",searchTitle);
	const search_results = faqs.data.filter((item) =>
        item.title.toLowerCase().includes(searchTitle.toLowerCase())
      );
	console.log("search_results",search_results);
	///setFaqs(search_results);
} else {
refreshData();
}*/
  };

  const initFetch = useCallback(() => {
    dispatch(retrieveFaqs());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const refreshData = () => {
    setCurrentFaq(null);
    setCurrentIndex(-1);
    FaqService.getAll().then((x) => setFaqs(x));
  };

  const setActiveFaq = (Faq, index) => {
    setCurrentFaq(Faq);
    setCurrentIndex(index);
  };

  const removeAllFaqs = () => {
    if (window.confirm("Stergi toate intrebarile ? ")) {
      dispatch(deleteAllFaqs())
        .then((response) => {
          refreshData();
          toast.success("Intrebarile au fost sterse");
        })
        .catch((e) => {
          //console.log(e);
          toast.error(e);
        });
    }
  };

  const findByTitle = () => {
    refreshData();
    //dispatch(findFaqsByTitle({ title: searchTitle }));
    console.log("Faqs", faqs);

    console.log("searchTitle : ", searchTitle);
    const search_results = faqs.data.filter((item) =>
      item.title.toLowerCase().includes(searchTitle.toLowerCase())
    );
    console.log("search_results", search_results);
    ///setFaqs(search_results);
  };

  const removeFaq = (id) => {
    if (window.confirm("Stergi intrebare? " + id)) {
      //const id = id;
      /* setFaqs(faqs.data.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));*/
      dispatch(deleteFaq(id))
        .then((response) => {
          refreshData();
          toast.success("Intrebare a fost stersa");
        })
        .catch((e) => {
          //console.log(e);
        });
      initFetch();
    }
  };

  return (
    <Box m="20px">
      <Header
        title="Lista de Intrebari"
        subtitle="Vă rugăm să faceți clic pe o intrebare..."
      />
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Căutați după intrebare"
              value={searchTitle}
              onChange={onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={findByTitle}
              >
                Cauta
              </button>
              <Link to={"/faqs"} className="nav-link btn btn-success">
                Adauga
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4></h4>

          <ul className="list-group">
            {faqs &&
              faqs.data.map((Faq, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => setActiveFaq(Faq, index)}
                  key={index}
                >
                  {Faq.title}
                  <div style={{ whiteSpace: "nowrap" }}>
                    <Link
                      to={"/Faq/" + Faq.id}
                      state={Faq}
                      className="btn btn-sm btn-info mr-1"
                    >
                      <i className="fas fa-edit"></i>
                    </Link>
                    <button
                      onClick={() => removeFaq(Faq.id)}
                      className="btn btn-sm btn-danger"
                      style={{ width: "40px" }}
                      disabled={Faq.isDeleting}
                    >
                      {Faq.isDeleting ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <span>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </li>
              ))}
          </ul>

          <button className="m-3 btn btn-sm btn-danger" onClick={removeAllFaqs}>
            Sterge tot
          </button>
        </div>
        <div className="col-md-6">
          {currentFaq ? (
            <div>
              <div>
                <label>
                  <strong>Intrebare:</strong>
                </label>{" "}
                {currentFaq.title} ?
              </div>
              <div>
                <label></label>{" "}
                {currentFaq.img && (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/test/files/" +
                      currentFaq.img
                    }
                    className="img-responsive img-fluid"
                  />
                )}
              </div>
              <div>
                <label>
                  <strong>Raspuns:</strong>
                </label>{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(currentFaq.description),
                  }}
                ></p>
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentFaq.published ? "Publicat" : "Draft"}
              </div>

              <Link
                to={"/Faq/" + currentFaq.id}
                state={currentFaq}
                className="badge badge-warning"
              >
                Modifica
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Vă rugăm să faceți clic pe o pagină...</p>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

//export default FaqsList;
export { FaqList };
