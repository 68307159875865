import React, { useEffect, useState, useRef } from "react";

import { Link, useParams } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
//import axios from "axios";
//import http from "../../http-common";
import UploadService from "../../_services/FileUploadService";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { authActions } from "_store";
import { SeoService, alertService, accountService } from "../../_services";
import { seoActions } from "../../_store";
import AutoSelect from "../../_helpers/autoselect";

import Select, { OptionsType, ValueType } from "react-select";
import { Options, OnChangeValue } from "react-select";
import slugify from "react-slugify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import slugify from 'react-slugify';
import { Box } from "@mui/material";
import Header from "../../components/Header";
//import Program from './Program';
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { CloseButton } from "../../components/styles/CalendarStyles";
import Modal from "react-modal";
import VanillaJSONEditor from "../../hoc/VanillaJSONEditor";

const Arrays = (data, fieldName, fieldValue) => {
  let arrayItem = [];
  if (data && Array.isArray(data)) {
    data.map((item, key) => {
      arrayItem.push({ label: item[fieldName], value: item[fieldValue] });
      return null;
    });
  }
  return arrayItem;
};

const WriteSeo = ( {currSeo,  Oninchid} ) => {
  //console.log(state);
  //console.log(state);
  //console.log(state);
  const sdate = useLocation().state;
  console.log("sdate", currSeo, sdate );
  //const state = currSeo !== undefined ? currSeo : sdate;
  const state = currSeo !== undefined ? currSeo : sdate;
  console.log("seo state", state?.id, state.Seo);
  //console.log("currSeo", currSeo);
  const navigate = useNavigate();
  const [id, setId] = useState(state?.id || 0);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [value, setValue] = useState(state?.description || "");
  const [description, setDescription] = useState(state?.description || "");
  const [title, setTitle] = useState(state?.title || "");
  const [active, setActive] = useState(state?.active || false);
  const [keywords, setKeywords] = useState(state?.keywords || "");
  const [tip, setTip] = useState(state?.tip || "");
  //const [shortDescription, setShortDescription] = useState(state?.shortDescription || "");
  //const [isGeneralist, setIsGeneralist] = useState(state?.isGeneralist || false);
  //const [lastName, setLastName] = useState(state?.lastName || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState(state?.coverImage || "");
  // const [price, setPrice] = useState(state?.price || 0);
  // const [positionId, setPositionId] = useState(Arrays(state?.Position , "title", "id")|| []);
  //const [positionId, setPositionId] = useState(state?.PositionId || "");
  const [userId, setUserId] = useState(state?.UserId || "");
  console.log("userId :", userId);
  //const [userId, setUserId] = useState(Arrays(state?.User , "username", "id")|| []);
  const [allmain, setAllmain] = useState(null);
  const [allcateg, setAllcateg] = useState(null);
  const [allseo, setAllseo] = useState(null);
  const [users, setUsers] = useState(null);
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  // const { users } = useSelector(x => x.users);
  console.log("authUser", authUser?.id);
  const [showEditor, setShowEditor] = useState(false);
  const [content, setContent] = useState({
    json: {
      meta: [ {
        name: 'og:title',
        content:"",
      },
      {
        name: 'theme-color',
        content:"#000000",
      },],
      heading: [{
        H1: "",
        H2: '',
        H3: "",
        H4: "",
        H5: "",
        H6: "",
   }],
  },
   text: undefined
  });
  //const [modalOpen, setModalOpen] = useState(false);
  //const [selectedModal, setSelectedModal] = useState("");
  /*const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
    overlay: { zIndex: 1000 },
  };
  const openForm = () => {
    setSelectedModal("Program");
    openModal();
  };

  // Open Modal Function
  const openModal = () => {
    setModalOpen(true);
  };

  // Close Modal Function
 /* const closeModal = () => {
    setModalOpen(false);
  };*/

  const isAddMode = !id;
  //const authUser = useSelector(x => x.auth.user);
  const initialValues = {
    title: title,
    keywords: keywords,
    description: value,
    coverImage: pimg,
    tip: tip,
    active: active,
  };

  //const dispatch = useDispatch();

  useEffect(() => {
    //SeoService.getAllposition().then(x => setAllmain(x.data));
    accountService.getAll().then((x) => setUsers(x));
  }, []);
  //const { categories } = useSelector(x => x.categories);

  //const { categories } = useSelector(x => x.categories);
  //if(categories) {
  //  setAllmain(categories);
  //}
  //console.log(allmain);
  if (allmain && !allcateg) {
    console.log("Functii:", allmain);
    setAllcateg(Arrays(allmain, "title", "id"));
    console.log("pos:", allcateg);
  }
  if (users && !allseo) {
    //console.log(allmain.data);
    console.log(users);
    setAllseo(Arrays(users, "username", "id"));
    console.log(allseo);
    //console.log(allcateg);
  }
  //   //console.log(categories);
  //useSelector(x => x.categories);
  ////console.log(categ );

  const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.upload(file);
      // setPimg(res.data.message]);
      //console.log(res.data.message);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  async function loadimg() {
    if (file) {
      const imgUrl = await upload();
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
    // //window.alert(imagePreviews);
    //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Titlu este obligatoriu"),
    myfile: Yup.mixed().test(
      "2097152",
      "Fișierul încărcat este prea mare.",
      (value) => {
        return value && filesharhe_ref.current
          ? filesharhe_ref.current.files[0].size <= 2097152
            ? true
            : false
          : true;
      }
    ),
  });
  const closeEdit = async (e) => {
    console.log("Abandon");
    Oninchid(e);
  };
  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    loadimg();

    console.log(fields);

    //fields["description"] = value;
    fields["coverImage"] = pimg;
    fields["active"] = active;
    //fields["slug"] = slugify(fields["title"]);
    //fields["UserId"] = authUser?.id;
    console.log("fields", fields);
    //userId
    // fields["isGeneralist"] = isGeneralist;
    // window.alert(JSON.stringify({ fields}));
    // console.log(Object.values(PositionId)[1]);
    // console.log(Object.values(UserId)[1]);
    //const Pid = PositionId.map((e) => e.value);
    //console.log(Pid );

    // fields["slug"] = slugify(fields["title"]);
    //window.alert(JSON.stringify({ fields }));
    if (isAddMode) {
      createSeo(fields, setSubmitting);
    } else {
      updateSeo(id, fields, setSubmitting);
    }
  }

  function createSeo(fields, setSubmitting) {
    SeoService.create(fields)
      .then(() => {
        toast.success("Seo adăugat cu succes");
        alertService.success("Produs adăugat cu succes", {
          keepAfterRouteChange: true,
        });

        navigate("/admin");
      })
      .catch((error) => {
        setSubmitting(false);
        //console.log(error);
        toast.error(error.response.data.message);

        alertService.error(error);
      });
  }

  function updateSeo(id, fields, setSubmitting) {
    //console.log("Update"+id);
    SeoService.update(id, fields)
      .then((num) => {
        console.log("Num :", num);
        //console.log("fields",fields);
       // const test1=[...currSeo, fields];
       
        //console.log(test1);
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        toast.success("Actualizare cu succes " + num.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSubmitting(false);
        if (currSeo !== undefined) {
          let test1 = Object.assign({},{id: currSeo.id}, fields );
          closeEdit(test1);
        } else {
          navigate("/admin");
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error); //.response.data.message
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        //alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Box m="20px">
            <Form>
              <h1>{isAddMode ? "Adaugă Seo" : "Editează  Seo"}</h1>
              {/*   {isAddMode ? '' :             <Button color="secondary" startIcon={<AddIcon />} onClick={openForm}>
        Program de lucru
        </Button>} */}
              <div className="form-row">
                <div className="form-group col-9">
                  <label>Titlu</label>
                  <Field
                    name="title"
                    type="text"
                    className={
                      "form-control" +
                      (errors.title && touched.title ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>

                <div className="form-group col-3">
                  <label>Tip</label>
                  <Field
                    name="tip"
                    as="select"
                    className={
                      "form-control" +
                      (errors.tip && touched.tip ? " is-invalid" : "")
                    }
                  >
                    <option value=""></option>
                    <option value="home">Home</option>
                    <option value="pages">Pagini</option>
                    <option value="project">Proiecte</option>
                    <option value="services">Seo</option>
                  </Field>

                  <ErrorMessage
                    name="tip"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-3">
                  <label>
                    Status{" "}
                    {active ? (
                      <div className="badge badge-primary">Activ</div>
                    ) : (
                      <div className="badge badge-danger">Inactiv</div>
                    )}
                    <Field
                      style={{ marginLeft: "1.5em", marginTop: "1.3em" }}
                      type="checkbox"
                      name="active"
                      onChange={(e) => setActive(!active)}
                      className={
                        "form-control d-none" +
                        (errors.active && touched.active ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="active"
                      component="div"
                      className="invalid-feedback"
                    />
                  </label>
                </div>

                <div className="form-group col-9">
                  <small>
                    Sunt acceptate poze doar cu extensia jpg, gif, png, webp sub
                    2Mb. Rezolutie recomandata 800x400
                  </small>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>Descriere</label>
                  <Field
                    name="description"
                    type="text"
                    className={
                      "form-control" +
                      (errors.description && touched.description
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-12">
                  <label>Cuvinte cheie</label>
                  <Field
                    name="keywords"
                    type="text"
                    className={
                      "form-control" +
                      (errors.keywords && touched.description
                        ? " is-invalid"
                        : "")
                    }
                  />
                  <ErrorMessage
                    name="keywords"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>
              {/*    <div className="form-row">
                <div className="form-group col">
                  <div className="editorContainer">
                    <label>Descriere</label>
                    <Field
                      name="description"
                      value={value}
                      type="hidden"
                      className={
                        "form-control" +
                        (errors.description && touched.description
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ReactQuill
                      className="editor"
                      theme="snow"
                      value={value}
                      onChange={setValue}
                    />
                    <ErrorMessage
                      name="description"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div> */}

              <div className="form-row">
                <div className="form-group col-8">
                  <label></label>
                  <Field
                    name="image"
                    type="hidden"
                    value={pimg}
                    className={
                      "form-control" +
                      (errors.img && touched.img ? " is-invalid" : "")
                    }
                  />
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="invalid-feedback"
                  />

                  <label className="file btn btn-sm btn-info" htmlFor="file">
                    Incarca imaginea
                  </label>

                  <Field
                    innerRef={filesharhe_ref}
                    type="file"
                    id="file"
                    name="myfile"
                    onChange={handleClick}
                    accept=".jpg, .png, .gif, .svg, .webp"
                  />
                  <ErrorMessage name="myfile" />
                  {pimg && !file && (
                    <img
                      src={
                        process.env.REACT_APP_API_URL + "/test/files/" + pimg
                      }
                      alt={pimg}
                      height="30vh"
                      align="center"
                      className="img-fluid img-responsive"
                    />
                  )}

                  {imagePreviews && (
                    <>
                      {imagePreviews.map((img, i) => {
                        return (
                          <img
                            src={img}
                            alt={"image-" + i}
                            key={i}
                            height="80px"
                            align="center"
                            className="img-fluid img-responsive"
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-3">
                <label>
          <input
            type="checkbox"
            checked={showEditor}
            onChange={() => setShowEditor(!showEditor)}
          />{" "}
          Vezi meta, heading ...
        </label>
        </div>

        {showEditor && (
        <>
         
          <div className="form-group col-9">
            <VanillaJSONEditor
              content={content}
              readOnly={false}
              onChange={setContent}
            />
          </div>
          <pre>
          <code>{JSON.stringify(content, null, 2)}</code>
        </pre>
        </>
      )}
              </div>
              {/*   <div className="form-row">
                <div className="form-group col-7">
                  <label>Seo Slug</label>

                  <Field
                    name="slug"
                    type="text"
                    value={slug}
                    readOnly
                    className={
                      "form-control" +
                      (errors.slug && touched.slug ? " is-invalid" : "")
                    }
                  />

                  <ErrorMessage
                    name="slug"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div> */}

              <div className="form-group">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  Salvează
                </button>
                <Button
                  onClick={() => {
                    if (currSeo !== undefined) {
                      closeEdit();
                    } else {
                      navigate("/admin");
                    }
                  }}
                  to={currSeo !== undefined ? "#" : "/admin"}
                  className="btn btn-link"
                >
                  Abandon
                </Button>
              </div>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};

export { WriteSeo };
