import http from "../http-common";

const getAll = () => {
  return http.get("/Faq");
};

const get = id => {
  return http.get(`/Faq/${id}`);
};

const create = data => {
  return http.post("/Faq", data);
};

const update = (id, data) => {
  return http.put(`/Faq/${id}`, data);
};

const remove = id => {
	//window.alert(id);
  return http.delete(`/Faq/${id}`);
};

const removeAll = () => {
  return http.delete(`/Faq`);
};

const findByTitle = title => {
  return http.get(`/Faq?title=${title}`);
};

const FaqdataService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle
};

export default FaqdataService;
//export {PageService};