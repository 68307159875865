import { fetchWrapper, history } from '../_helpers';
import http from "../http-common";

const getAll = () => {
  return http.get(`/servicii/list`);
};
const getAllactiv = () => {
  return http.get(`/servicii/activ`);
};
const get = id => {
  return http.get(`/servicii/id/${id}`);
};


const getbyslug = slug => {
  return http.get(`/servicii/slug/${slug}`);
};


const create = data => {
  return http.post(`/servicii`, data);
};

const update = (id, data) => {
	//console.log(id);
	 return http.put(`/servicii/${id}`, data);
 // return http.put("/servicii/${id}", data);
};

const remove = id => {
  return http.delete(`/servicii/${id}`);
};



const ServiciiService = {
  getAll,
  getAllactiv,
  get,
  create,
  update,
  remove,
  getbyslug
};

//export default PageService;
export {ServiciiService};