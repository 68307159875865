import React from 'react';
import './image.css';
import { toast } from 'react-toastify';
import { Box } from "@mui/material";




const Imgligth = ({img}) => {
  function getExtension(filename) {
    return filename.split('.').pop()
  };
  return (
    <Box m="20px">

                                        <div className="vh-80 vw-80 imgf" style={{alignItems:'center', justifyContent:'center'}} >
                                        {getExtension(img).toLowerCase() === "pdf" ?(
        <iframe src={img} style={{ height: "60%", width: "60%" }} title={`${img}`}/>
      ) : (
                                            <img src={`${img}`} alt="img" className="img-fluid" align="center" />
                                            )}
 

                                        </div>

      </Box>                              
    )
}
export default Imgligth;
