import http from "../http-common";

const getAll = () => {
  return http.get(`/seo`);
};

const get = id => {
  return http.get(`/seo/${id}`);
};

const create = data => {
  return http.post(`/seo`, data);
};

const update = (id, data) => {
  return http.put(`/seo/${id}`, data);
};

const remove = id => {
  return http.delete(`/seo/${id}`);
};

const removeAll = () => {
  return http.delete(`/seo`);
};

const findByTitle = title => {
  return http.get(`/seo?title=${title}`);
};

const SeoService = {
  getAll,
  get,
  create,
  update,
  remove,
  removeAll,
  findByTitle
};

//export default PageService;
export {SeoService};