import React, { useEffect, useState, useRef } from 'react';

import { Link, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';
import { accountService, alertService } from '../../_services';
import UploadService from "../../_services/FileUploadService";
import RichTextEditor from "../../_helpers/RichTextEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import slugify from 'react-slugify';
import { Box } from '@mui/material';
import Header from "../../components/Header";

const Write = () => {
  const state = useLocation().state;
  const navigate = useNavigate();
  //  const { id } = state?.id; //match.params;
  const [id, setId] = useState(state?.id || 0);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [value, setValue] = useState(state?.description || "");
  const [title, setTitle] = useState(state?.title || "");
  const [slug, setSlug] = useState(state?.slug || "");
  const [description, setDescription] = useState(state?.description || "");
  const [file, setFile] = useState(null);
  const [cat, setCat] = useState(state?.categoryId || "1");
  const [pimg, setPimg] = useState(state?.img || "");
    const [categoryId, setCategoryId] = useState(state?.categoryId || "");
  const isAddMode = !id;
  const authUser = useSelector(x => x.auth.user);
  const initialValues = {
    title: title,
    description: description,
    img: pimg,
    categoryId: categoryId,
    uid: authUser?.id,
    slug: slug
  };

 const filesharhe_ref = useRef();
 const upload = async () => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const res = await UploadService.upload(file);
    setPimg(res.data);
    // setPimg(res.data.message]);
    //console.log(res.data.message);
    return res.data;
  } catch (err) {
    //console.log(err);
    toast.error(err, {
      position: toast.POSITION.TOP_LEFT,
    });
  }
};


  async function loadimg() {

    //  setFile(fis);
    if (file) {


      // await setFile(file);
      const imgUrl = await upload();
   //   //window.alert(JSON.stringify({ imgUrl }));
      //  setPimg(imgUrl.message);
      //  	  //window.alert(imgUrl.message);
      //  //window.alert(pimg);
      //  fields["img"]=pimg;
    };

  }

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
   // //window.alert(e.target.files[0].name);
    //  //window.alert(JSON.stringify(e.target.files[0]));
    //  //window.alert(JSON.stringify({file}));
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
   // //window.alert(imagePreviews);
  //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required'),
 myfile: Yup.mixed().test('2097152', "Uploaded file is too big."
                ,(value) => {
                   return(
                    value && filesharhe_ref.current ?
                        (filesharhe_ref.current.files[0].size<=2097152? true: false)
                         : true)
                }),

  });
  const handleContentChange = (contentHtml) => {
    setValue(contentHtml);
  };

  async function onSubmit(fields, { setStatus, setSubmitting }) {
    try {
    setStatus();
    //loadimg();
    //fields["img"] = pimg;
    if (file) {
      const imgUrl = await upload();
      //  fields["avatar"]=imgUrl;
      //  setPimg(imgUrl);

      if (imgUrl) {
        fields["img"] = imgUrl;
        setPimg(imgUrl);
        console.log("imgUrl : ", imgUrl, fields["avatar"]);
      }
      // setPimg(imgUrl);
      // return true
    }
    //console.log(fields);
    fields["description"] = value;

    fields["slug"] = slugify(fields["title"]);
   // //window.alert(JSON.stringify({ fields }));
    if (isAddMode) {
      createPost(fields, setSubmitting);
    } else {
      updatePost(id, fields, setSubmitting);
    }
  } catch (err) {
    toast.error(err, {
      position: toast.POSITION.TOP_LEFT,
    });
    // return false;
  }
  }

  function createPost(fields, setSubmitting) {

    accountService.createposts(fields)
      .then(() => {
        toast.success("Postare adăugată cu succes");
        alertService.success('Post added successfully', { keepAfterRouteChange: true });

        navigate("/admin/posts");
      })
      .catch(error => {
        setSubmitting(false);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
        alertService.error(error);


      });
  }

  function updatePost(id, fields, setSubmitting) {

    accountService.updateposts(id, fields)
      .then(() => {
        alertService.success('Update successful', { keepAfterRouteChange: true });
        toast.success("Actualizare cu succes", {
          position: toast.POSITION.TOP_RIGHT
        });
        navigate("/admin/posts");
      })
      .catch(error => {
        setSubmitting(false);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT
        });
        alertService.error(error);
      });
  }



  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting, setFieldValue }) => {


        return (
	 <Box m="20px">
          <Form>
            <h1>{isAddMode ? 'Adaugă postare' : 'Editează postarea'}</h1>
            <div className="form-row">
              <div className="form-group col">
                <label>Titlu</label>
                <Field name="title" type="text" className={'form-control' + (errors.title && touched.title ? ' is-invalid' : '')} />
                <ErrorMessage name="title" component="div" className="invalid-feedback" />
              </div>


            </div>
            <div className="form-row">
              <div className="form-group col">
                <div className="editorContainer">
                  <label>Descriere</label>
                  <Field name="description" value={description} type="hidden" className={'form-control' + (errors.description && touched.description ? ' is-invalid' : '')} />
                  <RichTextEditor
                  content={value}
                  handleContentChange={handleContentChange}
                  placeholder="introduceți text aici..."
                />
                  <ErrorMessage name="description" component="div" className="invalid-feedback" />
                </div>
              </div>
            </div>
            <div className="form-row">
            <div className="form-group col-7">
                <label></label>
                <Field name="img" type="hidden"  value={pimg} className={'form-control' + (errors.img && touched.img ? ' is-invalid' : '')} />
                <ErrorMessage name="img" component="div" className="invalid-feedback" />


                <label className="file btn btn-sm btn-info" htmlFor="file">Incarca imaginea</label>

		    <Field innerRef={filesharhe_ref} type="file" id="file" name="myfile" onChange={handleClick}
                  accept=".jpg, .png, .gif, .svg, .webp"/>
    <ErrorMessage name='myfile'  />
                {pimg && (!file) && (
                  <img src={process.env.REACT_APP_API_URL + "/test/files/" + pimg} alt={pimg} height="80px" align="center" className='img-fluid' />

                )}

                {imagePreviews && (
                  <>
                    {imagePreviews.map((img, i) => {
                      return (
                        <img src={img} alt={"image-" + i} key={i} height="80px" align="center" className='img-fluid' />
                      );
                    })}
                  </>
                )}

              </div>

              <div className="form-group col-5">
                <label>Categorie</label>
                <Field name="categoryId" as="select" className={'form-control' + (errors.categoryId && touched.categoryId ? ' is-invalid' : '')}>
                  <option value=""></option>
                  <option value="1">Servicii</option>
                  <option value="2">Blog</option>
                  <option value="3">Altele</option>
                </Field>

                <ErrorMessage name="categoryId" component="div" className="invalid-feedback" />
              </div>
  
              <div className="form-group col-7">
                <label>Seo Slug</label>
                <Field name="uid" type="number" value={authUser?.id} className={'d-none'} />
                <Field name="slug" type="text" value={slug} readOnly className={'form-control' + (errors.slug && touched.slug ? ' is-invalid' : '')} />

                <ErrorMessage name="slug" component="div" className="invalid-feedback" />
              </div>
            </div>

            <div className="form-group">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                Salvează
              </button>
              <Link to={isAddMode ? '/admin/posts' : '/admin/posts'} className="btn btn-link">Abandon</Link>
            </div>
          </Form>
	 </Box>
        );
      }}
    </Formik>
  );
}

export { Write };