import React, { useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import { Container, InputAdornment, TextField } from "@mui/material";

export default function ImgCol({ ImgData, onModal, Onsterg }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [itemData, setItemData] = useState(ImgData);
  const [allData, setIAllData] = useState(ImgData);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    // setSearchTerm(event.target.value);
    setSearchTerm(e.target.value ? e.target.value : "");
    const search = e.target.value ? e.target.value : "";
    console.log("search : ", searchTerm, search);
    setItemData(
      allData.filter((item, index) => {
        return item.name.toLowerCase().includes(search.toLowerCase());
      })
    );
  };
  const handleDeleteField = (e, index) => {
   const values = [...allData];
   /*   console.log("e este : ", e, index);
   values.splice(
      allData.findIndex((element) => element.name === index),
      1
    );
    setIAllData(values);*/
    Onsterg(index);
    // setItemData(values);
    setItemData(
      values.filter((item, index) => {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
    // handleChange(searchTerm);
  };
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  // console.log(itemData[0]);
  const handleClickOpen = (e) => {
    setCurrentImage(e);
    console.log("index", e);
    console.log(`current index is ${currentImage}`);
    console.log(itemData[`${currentImage}`]);
    const values = [...itemData];
    //values[`${e}`]['status'] = true;
    values[`${e}`]["status"] = !values[`${e}`]["status"];
    //values[index].value = e.target.value;
    setItemData(values);
    console.log("MI:", itemData[`${currentImage}`]);
    const count = values.filter((item) => item.status === true).length;
    console.log("Selectate", count);
    //  itemData[`${currentImage}`]['status'] = true;
    // setOpen(true);
  };

  // sx={{ width: 600, height: 600 }}
  /*sx={{
    columnCount: {
      xs: '1 !important',
      sm: '2 !important',
      md: '3 !important',
      lg: '4 !important',
      xl: '5 !important',
    },
  }} cols={3} gap={18}
  
  spacing={80} justify="center"
  
  */

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="md" sx={{ mt: 20 }}>
        <TextField
          id="search"
          type="search"
          label="Cauta"
          value={searchTerm}
          onChange={handleChange}
          sx={{ width: "90%"}}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item>
            <ImageList
              gap={12}
              sx={{
                gridTemplateColumns:
                  "repeat(auto-fill, minmax(200px, 1fr))!important",
              }}
            >
              {itemData.map((item, index) => (
                <ImageListItem
                  key={item.url}
                  style={item.status ? { border: "5px solid green" } : {}}
                >
                  <img
                    src={`${item.url}?w=128&fit=crop&auto=format`}
                    srcSet={`${item.url}?w=128&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.name}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={item.name.split(".", 1)}
                    position="below"
                    actionIcon={
                      <>
                        <IconButton
                          onClick={(e) => onModal(item.url)}
                          sx={{ color: "rgba(0, 255, 0, 0.3)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <InfoIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => handleDeleteField(e, item.name)}
                          sx={{ color: "rgba(255, 0, 0, 0.54)" }}
                          aria-label={`info about ${item.name}`}
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
