import * as React from "react";
import { Link, useParams } from "react-router-dom";
import UploadImages from "./ImagesUpload";
import { SeoList, WriteSeo } from "./seo";
//import { useTheme } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//import { tokens } from "../theme";

/*interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}*/
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Overview() {
  //const theme = useTheme();
  // const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Seo" {...a11yProps(0)} />
          <Tab label="SiteMap" {...a11yProps(1)} />
          <Tab label="Media" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <SeoList />
      </TabPanel>
      <TabPanel value={value} index={1}>
        Generare Sitemap
      </TabPanel>

      <TabPanel value={value} index={2}>
        <>
          {/* <div className="container text-center">
        <b>Media</b>
      </div> */}
          <div className="container">
            <div className="my-3 justify-center items-center">
              <b>Încărcați mai multe imagini</b>
            </div>

            <UploadImages />
          </div>
        </>
      </TabPanel>
    </Box>
  );
}

export { Overview };
