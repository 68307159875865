import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FaqDataService from "../_services/FaqDataService";

const initialState = [];

export const createFaq = createAsyncThunk(
  "Faqs/create",
  async ({ title, description, img, categoryId, slug }) => {
    const res = await FaqDataService.create({
      title,
      description,
      img,
      categoryId,
      slug,
    });
    return res.data;
  }
);

export const retrieveFaqs = createAsyncThunk("Faqs/retrieve", async () => {
  const res = await FaqDataService.getAll();
  return res.data;
});

export const updateFaq = createAsyncThunk(
  "Faqs/update",
  async ({ id, data }) => {
    const res = await FaqDataService.update(id, data);
    return res.data;
  }
);

export const deleteFaq = createAsyncThunk("Faqs/delete", async (id) => {
  const res = await FaqDataService.remove(id);
  return res.data;
});

export const deleteAllFaqs = createAsyncThunk("Faqs/deleteAll", async () => {
  const res = await FaqDataService.removeAll();
  return res.data;
});

export const findFaqsByTitle = createAsyncThunk(
  "Faqs/findByTitle",
  async ({ title }) => {
    const res = await FaqDataService.findByTitle(title);
    return res.data;
  }
);

const FaqSlice = createSlice({
  name: "Faq",
  initialState,
  extraReducers: {
    [createFaq.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [retrieveFaqs.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [updateFaq.fulfilled]: (state, action) => {
      const index = state.findIndex((Faq) => Faq.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
    },
    [deleteFaq.fulfilled]: (state, action) => {
      let index = state.findIndex(({ id }) => id === action.payload.id);
      state.splice(index, 1);
    },
    [deleteAllFaqs.fulfilled]: (state, action) => {
      return [];
    },
    [findFaqsByTitle.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = FaqSlice;
export default reducer;
