const events = [
  { title: "Eveniment pe intreaga zi", start: getDate("YEAR-MONTH-01") },

  {
    title: "tratament",
    start: "YEAR-MONTH-17",
    end: getDate("YEAR-MONTH-19"),
		backgroundColor: "yellow",
		textColor: "black"
  },

  {
    title: "Consultaţie",
    start: getDate("YEAR-MONTH-18T08:30:00+00:00"),
    end: getDate("YEAR-MONTH-18T10:30:00+00:00"),
		backgroundColor: "yellow",
		textColor: "black"
  },
  { title: "Hifu", start: getDate("YEAR-MONTH-18T10:30:00+00:00"), end: getDate("YEAR-MONTH-18T12:30:00+00:00"), backgroundColor: "purple" },
  { title: "Crioripoliza", start: getDate("YEAR-MONTH-18T13:30:00+00:00"),end: getDate("YEAR-MONTH-18T14:30:00+00:00"), backgroundColor: "green"  },
  { title: "Manichuira", start: getDate("YEAR-MONTH-18T15:30:00+00:00"),end: getDate("YEAR-MONTH-18T16:30:00+00:00") },
  { title: "Radiofrecvență", start: getDate("YEAR-MONTH-18T20:00:00+00:00") },
 { title: "Biocurenti ", start: getDate("YEAR-MONTH-19T07:00:00+00:00"),end: getDate("YEAR-MONTH-19T14:30:00+00:00"), backgroundColor: "green" }
];

function getDate(dayString) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
}

export default events;
