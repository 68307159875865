import React, { useState, useEffect } from "react";
import "./drag.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

function Dragimg({ defaultList, onChange }) {
  console.log("def", defaultList); //myArray = JSON.parse(myJSON);
  const [itemList, setItemList] = useState(defaultList);
  console.log("itemList", itemList); 
 useEffect(() => {
    //ProjectService.getAllposition().then(x => setAllmain(x.data));
    setItemList(defaultList);

  }, []);
  //const [itemList, setItemList] = useState(defaultList);
  console.log("item list : ", itemList);
  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
    onChange(updatedList);
    //onChange(JSON.stringify(updatedList));
    console.log(itemList);
  };
  const deleteField = (e, index) => {
    const values = [...itemList];
    values.splice(index, 1);
    setItemList(values);
    onChange(values);
  };

  return (
    <div className="Drag">
      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
            {itemList.length && <> {itemList.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided) => (
                    <div
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div className="flex container items-center">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "/products/image/" +
                            item
                          }
                          width="100px"
                          align="left"
                        />
                        <button
                          onClick={(e) => deleteField(e, index)}
                          className="badge badge-danger mr-2 ml-3 pull-right"
                        >
                          X
                        </button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
                   </>     }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default Dragimg;
