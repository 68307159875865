import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import Header from "../../components/Header";
import {
  retrievePages,
  findPagesByTitle,
  deleteAllPages,
  deletePage,
} from "../../_store/pages";
import { PageService } from "../../_services/PageService";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";

const PagesList = () => {
  const confirm = useConfirm();
  const [currentPage, setCurrentPage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchTitle, setSearchTitle] = useState("");
  const [allPosts, setAllPosts] = useState(null);
  const [pages, setPages] = useState(null);
  useEffect(() => {
    PageService.getAll().then((x) => setPages(x));
  }, []);

  const dispatch = useDispatch();
  const Pages = useSelector((state) => state.Pages);

  ////window.alert(JSON.stringify({pages}));
  const onChangeSearchTitle = (e) => {
    //refreshData();
    const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
    /*if (event.target.value !== '') {
   const searchTitle = e.target.value;
    setSearchTitle(searchTitle);
	console.log("searchTitle : ",searchTitle);
	const search_results = pages.data.filter((item) =>
        item.title.toLowerCase().includes(searchTitle.toLowerCase())
      );
	console.log("search_results",search_results);
	///setPages(search_results);
} else {
refreshData();
}*/
  };

  const initFetch = useCallback(() => {
    dispatch(retrievePages());
  }, [dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const refreshData = () => {
    setCurrentPage(null);
    setCurrentIndex(-1);
    PageService.getAll().then((x) => setPages(x));
  };

  const setActivePage = (Page, index) => {
    setCurrentPage(Page);
    setCurrentIndex(index);
  };

  const removeAllPages = () => {
    if (window.confirm("Stergi toate paginile ? ")) {
      dispatch(deleteAllPages())
        .then((response) => {
          refreshData();
          toast.success("Paginile au fost sterse");
        })
        .catch((e) => {
          //console.log(e);
          toast.error(e);
        });
    }
  };

  const findByTitle = () => {
    refreshData();
    //dispatch(findPagesByTitle({ title: searchTitle }));
    console.log("Pages", pages);

    console.log("searchTitle : ", searchTitle);
    const search_results = pages.data.filter((item) =>
      item.title.toLowerCase().includes(searchTitle.toLowerCase())
    );
    console.log("search_results", search_results);
    ///setPages(search_results);
  };

  const removePage = (id) => {
   // if (window.confirm("Stergi pagina? " + id)) {
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv Pagina ${id}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
      //const id = id;
      /* setPages(pages.data.map(x => {
            if (x.id === id) { x.isDeleting = true; }
            return x;
        }));*/
      dispatch(deletePage(id))
        .then((response) => {
          refreshData();
          toast.success("Pagina a fost stersa");
        })
        .catch((e) => {
          //console.log(e);
        });
      initFetch();
    })
    .catch(() => {
      //console.log("Ștergerea a fost anulată.")
      toast.error("Ștergerea a fost anulată");
    });
   // }
  };

  return (
    <Box m="20px">
      <Header
        title="Lista de pagini"
        subtitle="Vă rugăm să faceți clic pe o pagină..."
      />
      <div className="list row">
        <div className="col-md-8">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Căutați după titlu"
              value={searchTitle}
              onChange={onChangeSearchTitle}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={findByTitle}
              >
                Cauta
              </button>
              <Link to={"/pages"} className="nav-link btn btn-success">
                Adauga
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4></h4>

          <ul className="list-group">
            {pages &&
              pages.data.map((Page, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => setActivePage(Page, index)}
                  key={index}
                >
                  {Page.title}
                  <div style={{ whiteSpace: "nowrap" }}>
                    <Link
                      to={"/Pages/" + Page.id}
                      state={Page}
                      className="btn btn-sm btn-info mr-1"
                    >
                      <i className="fas fa-edit"></i>
                    </Link>
                    <button
                      onClick={() => removePage(Page.id)}
                      className="btn btn-sm btn-danger"
                      style={{ width: "40px" }}
                      disabled={Page.isDeleting}
                    >
                      {Page.isDeleting ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <span>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      )}
                    </button>
                  </div>
                </li>
              ))}
          </ul>

          <button
            className="m-3 btn btn-sm btn-danger"
            onClick={removeAllPages}
          >
            Sterge tot
          </button>
        </div>
        <div className="col-md-6">
          {currentPage ? (
            <div>
              <div>
                <label>
                  <strong>Titlu:</strong>
                </label>{" "}
                {currentPage.title}
              </div>
              <div>
                <label></label>{" "}
                {currentPage.img && (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/test/files/" +
                      currentPage.img
                    }
                    className="img-responsive img-fluid"
                  />
                )}
              </div>
              <div>
                <label>
                  <strong>Continut:</strong>
                </label>{" "}
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(currentPage.description),
                  }}
                ></p>
              </div>
              <div>
                <label>
                  <strong>Status:</strong>
                </label>{" "}
                {currentPage.published ? "Publicat" : "Draft"}
              </div>

              <Link
                to={"/Pages/" + currentPage.id}
                state={currentPage}
                className="badge badge-warning"
              >
                Modifica
              </Link>
            </div>
          ) : (
            <div>
              <br />
              <p>Vă rugăm să faceți clic pe o pagină...</p>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

//export default PagesList;
export { PagesList };
