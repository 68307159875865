import { BehaviorSubject } from 'rxjs';

//import config from 'config';
import { fetchWrapper, history } from '../_helpers';
import http from "../http-common";
const userSubject = new BehaviorSubject(null);
const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;
const usersUrl = `${process.env.REACT_APP_API_URL}/users`;
const postUrl = `${process.env.REACT_APP_API_URL}/posts`;
export const accountService = {
    login,
    logout,
    refreshToken,
    register,
    verifyEmail,
    forgotPassword,
    validateResetToken,
    resetPassword,
    getAll,
    getStats,
    getById,
    create,
    update,
    getpostsAll,
    getpostsById,
    createposts,
    updateposts,
    deleteposts,
    delete: _delete,
    user: userSubject.asObservable(),
    get userValue () { return userSubject.value }
};

function login(email, password) {
	////window.alert(email);
    return fetchWrapper.post(`${usersUrl}/login`, { email, password })
        .then(user => {
            // publish user to subscribers and start timer to refresh token authenticate
			//console.log(user);
            userSubject.next(user);
            startRefreshTokenTimer();
				// //window.alert(JSON.stringify({user}));
            return user;
        })
}

function logout(id) {
    // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
   // fetchWrapper.post(`${baseUrl}/revoke-token`, {});
   console.log("logout id : ",id);
	//return fetchWrapper.delete(`${usersUrl}/logout/${id}`);
		return http.delete(`/users/logout/${id}`);
   // stopRefreshTokenTimer();
   // userSubject.next(null);
   // history.push('/account/login');
 //  return id;
}

function refreshToken() {
    return;
    /* fetchWrapper.post(`${baseUrl}/refresh-token`, {})
        .then(user => {
            // publish user to subscribers and start timer to refresh token
            userSubject.next(user);
            startRefreshTokenTimer();
            return user;
        });**/
}

function register(params) {
	//console.log(params);
    return fetchWrapper.post(`${usersUrl}`, params);
}

function verifyEmail(emailToken) {
    //return fetchWrapper.post(`${baseUrl}/verify-email`, { token });
	    return fetchWrapper.get(`${usersUrl}/confirm/${emailToken}`);
}

function forgotPassword(email) {
    return fetchWrapper.post(`${usersUrl}/forgot-password`, { email });
}

function validateResetToken(token) {
	//console.log(token);
    return fetchWrapper.post(`${usersUrl}/validate-reset-token`, { token });
}

function resetPassword({ token, password, confirmPassword }) {
    return fetchWrapper.post(`${usersUrl}/reset-password`, { token, password, confirmPassword });
}

function getAll() {
    return fetchWrapper.get(`${usersUrl}/all`);
}
function getStats() {
    return fetchWrapper.get(`${usersUrl}/stats`);
}
function getpostsAll() {
    return fetchWrapper.get(postUrl);
}

function getById(id) {
    return fetchWrapper.get(`${usersUrl}/${id}`);
}

function getpostsById(id) {
    return fetchWrapper.get(`${postUrl}/${id}`);
}

function create(params) {
    return fetchWrapper.post(usersUrl, params);
}
function createposts(params) {
	//console.log(params);
    return fetchWrapper.post(postUrl, params);
}

function update(id, params) {
	//console.log(params)
	////console.log(id);    return fetchWrapper.put(`${usersUrl}/${id}`, params)
	  return http.put(`/users/${id}`, params);
    
}

function updateposts(id, params) {
    return fetchWrapper.put(`${postUrl}/${id}`, params);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
	console.log(id);
    return fetchWrapper.delete(`${usersUrl}/${id}`)
        .then(x => {
            // auto logout if the logged in user deleted their own record
            let cucu = localStorage.getItem('user');
            if (id === cucu[0].id) {
                logout(id);
            }
            return x;
        });
}

function deleteposts(id) {
    return fetchWrapper.delete(`${postUrl}/${id}`);
}
//deleteposts helper functions

let refreshTokenTimeout;

function startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    refreshTokenTimeout = setTimeout(refreshToken, timeout);
}

function stopRefreshTokenTimer() {
    clearTimeout(refreshTokenTimeout);
}
