import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { SeoService } from "../../_services";
import { seoActions } from "../../_store";
import Header from "../../components/Header";
import { Box, Typography, useTheme } from "@mui/material";
import DOMPurify from "dompurify";
import "react-quill/dist/quill.snow.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  roRO,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { DeleteOutline } from "@mui/icons-material";
import { toast } from "react-toastify";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

function SeoList({ match }) {
  const confirm = useConfirm();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { path } = useLocation(); // match;
  const [currentPage, setCurrentPage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [seo, setSeo] = useState(null);

  useEffect(() => {
    SeoService.getAll().then((x) => setSeo(x.data));
    //      dispatch(seoActions.getAll());
    //setSeo(useSelector(x => x.seo));
    //setProduct(seo);
  }, []);

  // const { seo } = useSelector(x => x.seo);
  ////console.log({seo});

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      seo.find((row) => row.id === id)
    );

    console.log("Seo : ", seo);
    if (selectedRowsData.length) {
      console.log("selectedRowsData:", selectedRowsData);
      console.log(selectedRowsData[0].id, selectedRowsData[0], ids.id);
      setActivePage(selectedRowsData[0], selectedRowsData[0].id);
      console.log("currentPage", currentPage);
    }
  };
  const setActivePage = (Page, index) => {
    setCurrentPage(Page);
    setCurrentIndex(index);
  };

  const deleteSeo = (id) => {
    confirm({
      title: "Esti Sigur",
      description: `Se va șterge definitiv Seo ${id}.`,
      confirmationText: "da",
      cancellationText: "renunta",
    })
      .then(() => {
        setSeo(
          seo.map((x) => {
            if (x.id === id) {
              x.isDeleting = true;
            }
            return x;
          })
        );
        SeoService.remove(id).then(() => {
          setSeo((seo) => seo.filter((x) => x.id !== id));
          toast.success("Seo a fost sters cu succes");
        });
      })
      .catch(() => {
        //console.log("Ștergerea a fost anulată.")
        toast.error("Ștergerea a fost anulată");
      });
  };

  const columns = [
    { field: "id", headerName: "Id", hide: true },
    {
      field: "title",
      headerName: "Titlu",
      width: 200,
      cellClassName: "name-column--cell",
    },
    {
      field: "tip",
      headerName: "Tip",
      width: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "coverImage",
      headerName: "Poza",
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.coverImage ? (
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  "/products/image/" +
                  params.row.coverImage
                }
                className="img-fluid rounded"
                width="60px"
              />
            ) : (
              <img src="assets/logo.png" className="img-fluid" width="60px" />
            )}
          </>
        );
      },
    },

    {
      field: "active",
      headerName: "Status",
      width: 90,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.active ? (
              <div className="badge badge-primary">Activ</div>
            ) : (
              <div className="badge badge-danger">Inactiv</div>
            )}
          </>
        );
      },
    },

    {
      field: "action",
      headerName: "",
      width: 100,
      renderCell: (params: any) => {
        return (
          <>
            <Link to={`/seo/write`} state={params.row}>
              <button className="btn btn-sm btn-primary mr-1">
                <i className="fas fa-edit"></i>
              </button>
            </Link>
            <DeleteOutline
              className="btn btn-xs btn-sm btn-danger"
              style={{ width: "30px", height: "30px" }}
              onClick={() => deleteSeo(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  function getStatus(params) {
    //console.log(params);
    return `${!params.row.active || "Activ"} ${params.row.active || "Inactiv"}`;
  }

  return (
    <Box m="20px">
      <div>
        <h1>Seo</h1>

        <Link to={`/seo/write`} className="btn btn-sm btn-success mb-2">
          Adăugați un Seo
        </Link>

        {seo && (
          <Box
            m="8px 0 0 0"
            height="60vh"
            width="90%"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
              "& .MuiChackbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              rows={seo}
              columns={columns}
              pageSize={5}
              localeText={roRO.components.MuiDataGrid.defaultProps.localeText}
              components={{ Toolbar: CustomToolbar }}
              onSelectionModelChange={(ids) => {
                //console.log(ids);
                //console.log(ids[0]);
                onRowsSelectionHandler(ids);
                // //console.log(params);
              }}
            />
          </Box>
        )}
      </div>

      <div className="col-md-6">
        {currentPage ? (
          <div>
            <h4>Seo</h4>
            <div>
              <label>
                <strong>Titlu:</strong>
              </label>{" "}
              {currentPage.title}
            </div>
            <div>
              <label></label>{" "}
              {currentPage.coverImage && (
                <img
                  src={
                    process.env.REACT_APP_API_URL +
                    "/test/files/" +
                    currentPage.coverImage
                  }
                  className="img-responsive img-fluid"
                  width="100%"
                />
              )}
            </div>
            <div>
              <label>
                <strong>Descriere:</strong>
              </label>{" "}
              {/*<p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(currentPage.description),
                }}
              ></p> */}
              <div className="ql-snow w-full mt-16 ">
                {" "}
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{ __html: currentPage.description }}
                />{" "}
              </div>
            </div>
            <div>
              <label>
                <strong>Keywords:</strong>
              </label>{" "}
              {currentPage.keywords}
            </div>
            <div>
              <label>
                <strong>Status:</strong>
              </label>{" "}
              {currentPage.active ? "Publicat" : "Pending"}
            </div>

            <Link
              className="badge badge-warning"
              to={`/seo/write`}
              state={currentPage}
            >
              <i className="fas fa-edit"></i>
            </Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Vă rugăm să faceți clic pe un titlu...</p>
          </div>
        )}
      </div>
    </Box>
  );
}

export { SeoList };
