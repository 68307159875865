import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { updateFaq, deleteFaq } from "../../_store/faqs";
//import FaqService from "../services/FaqService";
import { FaqService } from "../../_services/FaqService";
import RichTextEditor from "../../_helpers/RichTextEditor";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../style.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import slugify from "react-slugify";
import { Box } from "@mui/material";
import UploadService from "../../_services/FileUploadService";

const Faq = (props) => {
  const { id } = useParams();
  const state = useLocation().state;
  const navigate = useNavigate();
  const [value, setValue] = useState(state?.description || "");
  const [file, setFile] = useState(null);
  const [pimg, setPimg] = useState("");
  const [categoryId, setCategoryId] = useState(state?.categoryId || "");
  const [title, setTilte] = useState(state?.title || "");
  const initialFaqState = {
    id: null,
    title: title,
    description: value,
    img: "",
    categoryId: categoryId,
    published: false,
  };
  console.log("initialFaqState", initialFaqState);
  const [currentFaq, setCurrentFaq] = useState(initialFaqState);
  const [message, setMessage] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);

  const filesharhe_ref = useRef();
  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await UploadService.upload(file);
      // setPimg(res.data.message]);
      //console.log(res.data.message);
      return res.data;
    } catch (err) {
      //console.log(err);
      toast.error(err, {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  async function loadimg() {
    if (file) {
      console.log("load", file.name);
      // await setFile(file);
      setPimg(file.name);
      const imgUrl = await upload();
    }
  }

  const handleClick = async (e) => {
    e.preventDefault();
    setFile(e.target.files[0]);
    setPimg(e.target.files[0].name);
    let images = [];

    for (let i = 0; i < e.target.files.length; i++) {
      images.push(URL.createObjectURL(e.target.files[i]));
    }

    //  setSelectedFiles(event.target.files);
    setImagePreviews(images);
    // //window.alert(imagePreviews);
    //  //window.alert(JSON.stringify({ imagePreviews }));
    /* if(file) {
     const imgUrl = await upload();
          setPimg(imgUrl.message);
     // //window.alert(pimg);
     // fields["img"]=pimg;
   };*/
  };

  const dispatch = useDispatch();

  const getFaq = (id) => {
    FaqService.get(id)
      .then((response) => {
        setCurrentFaq(response.data[0]);
        setPimg(currentFaq.img);
        console.log("currentFaq : ", currentFaq, response.data[0]);
        //setValue(currentFaq.description);
        ////window.alert(currentFaq.description);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    if (id) getFaq(id);
    setPimg(currentFaq.img);
    console.log("Pimg", pimg, currentFaq.img);
  }, [id]);

  const handleContentChange = (contentHtml) => {
    setValue(contentHtml);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentFaq({ ...currentFaq, [name]: value });
  };

  const handleEditorChange = (value) => {
    // const { name, value } = event.target;
    setCurrentFaq({ ...currentFaq, description: value });
  };

  const updateStatus = (status) => {
    // loadimg();
    // console.log("Pimg 3", pimg);
    const data = {
      id: currentFaq.id,
      title: currentFaq.title,
      description: value,
      published: status,
      categoryId: currentFaq.categoryId,
      slug: slugify(currentFaq.title),
    };

    dispatch(updateFaq({ id: currentFaq.id, data }))
      .unwrap()
      .then((response) => {
        //console.log(response);
        setCurrentFaq({ ...currentFaq, published: status });
        getFaq(id);
        setMessage("Starea a fost actualizată cu succes!");
        toast.success("Starea a fost actualizată cu succes!", {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((e) => {
        toast.error(e);
        console.log(e);
      });
  };

  const updateContent = () => {
    // setCurrentFaq({ ...currentFaq, description: value });
    loadimg();
    console.log("Pimg 2", pimg);
    const data = {
      id: currentFaq.id,
      title: currentFaq.title,
      description: value,
      img: pimg,
      categoryId: currentFaq.categoryId,
      published: currentFaq.published,
      slug: slugify(currentFaq.title),
    };
    ////window.alert(currentFaq.description);     dispatch(updateFaq({ id: currentFaq.id, data: currentFaq }))
    dispatch(updateFaq({ id: currentFaq.id, data }))
      .unwrap()
      .then((response) => {
        //console.log(response);
        setMessage("Intrebare a fost actualizată cu succes!");
        toast.success("Intrebare a fost actualizată cu succes!", {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/admin/faq");
      })
      .catch((e) => {
        //console.log(e);
        toast.error(e);
      });
  };

  const removeFaq = () => {
    if (window.confirm("Stergi intrebarea? " + currentFaq.title)) {
      dispatch(deleteFaq({ id: currentFaq.id }))
        .unwrap()
        .then(() => {
          navigate("/admin/faq");
        })
        .catch((e) => {
          //console.log(e);
        });
    }
  };

  return (
    <Box m="20px">
      <div>
        {currentFaq ? (
          <div className="edit-form">
            <h4>Intrebare</h4>
            <form>
              <div className="form-row">
                <div className="form-group col-9">
                  <label htmlFor="title">Interbare</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    value={currentFaq.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="form-group col-3">
                <label htmlFor="categoryId">Categorie</label>

                <select
                  name="categoryId"
                  id="categoryId"
                  className="form-control"
                  value={currentFaq.categoryId || ""}
                  onChange={handleInputChange}
                >
                  <option value=""></option>
                  <option value="1">BabySitter</option>
                  <option value="2">Menajera</option>
                  <option value="3">PetSitter</option>
                  <option value="4">Client</option>
                </select>
              </div>
              </div>
              <div className="form-group">
                <label htmlFor="description">Raspuns</label>
                <input
                  type="hidden"
                  className="form-control"
                  id="description"
                  name="description"
                  value={value}
                  onChange={handleInputChange}
                />

                <RichTextEditor
                  content={value}
                  handleContentChange={handleContentChange}
                  placeholder="introduceți text aici..."
                />
              </div>

              <div className="form-group">
                <label></label>
                {/*
                <Field name="img" type="hidden"  value={pimg} className={'form-control'} />
                <ErrorMessage name="img" component="div" className="invalid-feedback" /> */}

                <label className="file btn btn-sm btn-info" htmlFor="file">
                  Incarca imaginea
                </label>

                <input
                  innerref={filesharhe_ref}
                  type="file"
                  id="file"
                  name="myfile"
                  onChange={handleClick}
                  accept=".jpg, .png, .gif, .svg, .webp"
                />
                {/*  <ErrorMessage name='myfile'  /> */}
                {currentFaq.img && !file && (
                  <img
                    src={
                      process.env.REACT_APP_API_URL +
                      "/test/files/" +
                      currentFaq.img
                    }
                    alt={currentFaq.img}
                    height="80px"
                    align="center"
                    className="img-fluid"
                  />
                )}

                {imagePreviews && (
                  <>
                    {imagePreviews.map((img, i) => {
                      return (
                        <img
                          src={img}
                          alt={"image-" + i}
                          key={i}
                          height="80px"
                          align="center"
                          className="img-fluid"
                        />
                      );
                    })}
                  </>
                )}
              </div>

              <div className="form-group">
                <label>
                  <strong>Seo:</strong>
                </label>
                {currentFaq.slug}
              </div>
              <div className="form-group">
                <label>
                  <strong>Status:</strong>
                </label>
                {currentFaq.published ? "Publicat" : "Draft"}
              </div>
            </form>

            {currentFaq.published ? (
              <button
                className="badge badge-primary mr-2"
                onClick={() => updateStatus(false)}
              >
                Draft
              </button>
            ) : (
              <button
                className="badge badge-primary mr-2"
                onClick={() => updateStatus(true)}
              >
                Publica
              </button>
            )}

            <button className="badge badge-danger mr-2" onClick={removeFaq}>
              Sterge
            </button>

            <button
              type="submit"
              className="badge badge-success"
              onClick={updateContent}
            >
              Modifica
            </button>
            <Link to="/admin/faq" className="btn btn-link">
              Abandon
            </Link>
            <p>{message}</p>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a Faq...</p>
          </div>
        )}
      </div>
    </Box>
  );
};

//export default Faq;
export { Faq };
